export { data, style };
const data ={
		title: `<div style="text-align:center; color: rgb(248,192,0)">Testimonials</div>`,
		description: "",
		parents:{
			title: `<div style="text-align:center; letter-spacing: 5px;">WHAT PARENTS SAY ABOUT US</div>`,
			feedbacks:[
				{
					image: require("./../assets/kaushal.jpg"),
					title: "Kaushal",
					subtitle: "",
					description:"Aarna (10 years) has been attending the coding classes for the second session now. She is learning Scratch - which is apt and suitable for her age. She is getting a good understanding of basic programming concepts, thinking and rationalizing how to apply logic and building on them to create 'mini-projects'. The teachers are very friendly and patient with them and make sure they understand the concepts well. A special shout out to Amol for the behind the scenes effort and coordination throughout the program."
				},
				{
					image: require("./../assets/Suvelee.png"),
					title: "Suvelee",
					subtitle: "",
					description:"Code a Block got my son really excited about programming. The instructors are very dedicated, patient and encouraging. Their format of introducing programming and logic concepts via games is very unique and makes programming constructs very easy for kids to understand"
				},
				{
					image: require("./../assets/raj_agarwal.png"),
					title: "Raj Agarwal",
					subtitle: "",
					description:"My son 5th grader got started on online python with code-a-block. Teachers were outstanding, class size was limited and they paid attention to each and every kid. Another 2nd grader son started with scratch programming. Both my sons would eagerly wait for a session every week.I would recommend to have your kid try out a session.I was always thrilled when my 2nd grader say “I am coding"
				},
				{
					image: require("./../assets/parent2.png"),
					title: "Natasha",
					subtitle: "",
					description:"Code a Block introduced programming to my 7-year old in a fun and easy to understand manner. The instructors were patient, encouraging, and came up with interesting projects to code.  My daughter enjoyed the sessions"
				}
			],
			style:{
				titleBackgroundColor: "rgba(255,207,147,1)",
				contentBackgroundColor: "#00ffb8",
				static: true,
				partition: 20
			}
		},
		students:{
			title: `<div style="text-align:center; letter-spacing: 5px;">WHAT STUDENTS THINK ABOUT US</div>`,		
			feedbacks:[
			{
					image: require("./../assets/anish.jpg"),
					title: "Anish",
					subtitle: "New Jersey",
					description:"Over the course of 2 years being with Codeablock, I have learnt a lot of techniques in basic coding. I never knew that coding would be fun. The instructors were very helpful, tried to answer all the questions and made it look super easy which made me even clear my Level 1 Python test successfully. I would definitely recommend Codeablock."
				},
				{
					image: require("./../assets/julian.jpeg"),
					title: "Julian",
					subtitle: "",
					description:"Hi! I’m Julian and I’ve been doing Scratch and App Lab for over a year and it’s been really fun. It’s been very interesting to learn how to code! I’ve made a variety of fun games like a matching game, tic tac toe, a guessing game, and lots more! I also learned how to code a basic calculator and want to make my own Deal or No Deal game. The instructors teach me really well on how to do it all - on what the code means and how to use it - and that’s part of the reason why it’s so fun, because the instructors make it less confusing. I can’t wait to see all the other fun things I can do with Code a Block."
				},
				{
					image: require("./../assets/mouktika.jpg"),
					title: "Mouktika",
					subtitle: "Chantilly, VA",
					description:"Through Codeablock, I was able to open a door to the world of coding. I have been part of this organization for over a year now, and I can easily claim that I have learned so much. Not only is it educational, but it’s a super fun way to learn to code. I think my favorite part of this learning experience is how patient the instructors are. No matter what questions I ask, my instructor always takes time to help explain my doubts. So if you are looking to learn to code, I definitely recommend Codeablock for you."
				},
				{
					image: require("./../assets/akshaj.jpg"),
					title: "Akshaj",
					subtitle: "Herndon, VA",
					description:"I feel good to come into the class and I enjoy it a lot.I recommend other people to join the class."
				},	
				{
					image: require("./../assets/vishnu.jpeg"),
					title: "Vishnu",
					subtitle: "Herndon, VA",
					description:"When I started codablock I did not have anything about what coding is, but as I started learning it I became very interested. I think codeablock is fun because you can be creative. I am always waiting for the next class so that I can learn new blocks and create new games."
				},	
				{
					image: require("./../assets/avi.jpg"),
					title: "Avi",
					subtitle: "Herndon, VA",
					description:"Hi, my name is Avi and I am in 5th grade. I like Code A Block because after a few levels of teaching, you get good and can make your own games. Also, the teachers are very patient and help you a lot. I would recommend Code A Block to anyone who wants to code."
				},	
				{
					image: require("./../assets/marley.jpg"),
					title: "Marley",
					subtitle: "Herndon, VA",
					description:"The classes are very interactive and the teachers give good answers."
				},	
				{
					image: require("./../assets/deep.jpeg"),
					title: "Deep",
					subtitle: "Herndon, VA",
					description:"Code-a-Block sessions have been a great experience. The teachers are highly knowledgeable and easily accessible. Lessons are taught in detail, and the assignments that students code are great for critical thinking. I have learned a lot about the Python language so far and this class is perfect for preparing for real-world coding and careers."
				},
				{
					image: require("./../assets/jeet.jpeg"),
					title: "Jeet",
					subtitle: "Aldie, VA",
					description:"Code A Block helped me develop the basic fundamentals of Python. The teachers are very knowledgeable and understanding. I was intimated at first to learn coding, but now, I feel very confident and proud of myself of all the projects I have finished. Teachers give a perfect amount of homework to do on our own time, and are always accessible for help during the day. So far, I have finished the first two levels of python, and I am looking forward to many more. I would highly recommend Code A Block to anyone who wants to learn coding at any age. Learning Python is ideal for middle and high school level grade kids."
				},
				{
					image: require("./../assets/czaee.jpeg"),
					title: "Czaee",
					subtitle: "Herndon, VA",
					description:"Hi my name is Czaee and I am in 4th grade.I love to code in scratch because my teacher makes it fun and easy to follow along . The concepts are easy to learn and do. It helped me see the world in a different way , everything I see feels like it has a code inside. It is like a never ending game."
				},
				{
 					image: require("./../assets/Advait.jpeg"),
					title: "Advait",
					subtitle: "Pune, India",
					description:"The teachers in Code-A-Block are very friendly and helpful. I am in 9th Grade and love the python coding sessions. The levels are easy to understand and  I enjoyed making different games at various levels."
				},
				{
					image: require("./../assets/abir.jpeg"),
					title: "Abir",
					subtitle: "Herndon, VA",
					description:"Hi my name is Abir I am in third grade. I like this class because the teachers are very patient and helpful. When I was young I didn’t know about scratch now i can make more complex games and love to code the block"
				},
				{
					image: require("./../assets/advait.png"),
					title: "Advait",
					subtitle: "Herndon, VA",
					description:"The class is simple and easy to understand. The concept is easy to get a grasp on with the weekly assignments. They take it slowly and wait for everyone. They even double check your work and take the time to tell you what you did wrong or what you didn’t add. I really enjoy being in this class"
				},
				{
					image: require("./../assets/bodh.png"),
					title: "Bodh",
					subtitle: "Herndon, VA",
					description:"This is Bodh. I am 5th grader. The python classes were very informative and easy to follow. The teachers were very nice. They made python programming look simple. I would recommend this class to others"
				},
				{
					image: require("./../assets/student2.png"),
					title: "Simrat",
					subtitle: "Herndon, VA",
					description:"Before I took this class I knew nothing about python or coding in general. But they really helped me learn and I was able to learn a lot in just a few classes!"
				},
				{
 					image: require("./../assets/student1.png"),
					title: "Dhanush",
					subtitle: "Herndon, VA",
					description:"I like code a block because I got to learn more types of blocks from scratch and I made more sophisticated and fun games during the sessions."
				},
			],
			style:{
				 titleBackgroundColor: "rgba(255,207,147,1)",
				contentBackgroundColor: "lightblue",
				static: true,
				partition: 20
			}
		}
	},
	style = {
		title:{
			effect: "fade-left",
			color: 'white',
		}
	}