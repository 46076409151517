import React, {useEffect} from 'react';
import Parallax from './../parallax/parallax';
import WindowResize from './../windowResize';
import './information.css';

export default function Information({children, id, title, subtitle, description, style}) {
    const size = WindowResize();
    return (
    <div id={id} className={id + " information row-flex" + (style.backgroundType=='background-cover'? " background-cover":"")} style={{backgroundPosition:'center', backgroundSize: "cover", backgroundImage: style.backgroundImage?("url(" + style.backgroundImage + ")"):'none', backgroundColor: style.backgroundColor?style.backgroundColor:''}}>
      <div style={{width: '100%'}} className="row-flex center-flex page-padding">
        <div className="page-content">
          <div className="app-title" dangerouslySetInnerHTML={{__html: title}} style={{"width": "100%"}} />
          <div className="app-subtitle" dangerouslySetInnerHTML={{__html: subtitle}}></div>
          <div className="app-description" dangerouslySetInnerHTML={{__html: description}}></div>
          {children}
        
        </div>  
        {(style.backgroundType==='parallax' && <Parallax id={id} image={style.backgroundImage} opacity={style.opacity} gradient={style.gradient} backgroundColor={style.backgroundColor}/>)} 
      </div>
    </div>
  );
}
