import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";

import Home from './../home/home';
import Introduction from './../introduction/introduction';
import Courses from './../courses/courses';
import Company from './../company/company';
import Hiring from './../hiring/hiring';
import Testimonials from './../testimonials/testimonials';
import AboutUs from './../aboutUs/aboutUs';

const routeItems = [
  // { path: '/courses', component: Courses },
  // { path: '/company', component: Company },
  // { path: '/hiring', component: Hiring },
  // { path: '/testimonials', component: Testimonials },
  // { path: '/about-us', component: AboutUs },
  { path: '/', component: Home },
]
  

export default function AppRoutes() {
  return (
      <Switch>
        { routeItems.map(item=>(
          <Route path={item.path} component= {item.component} key={item.path}/>
        ))}
      </Switch>
  );
}