export { data, style };
const data ={
		title: `<div style='text-align: center; color: rgb(0,194,203)' >About Us</div>`,
		subtitle: `<div style='text-align: center;' >MEET THE TEAM</div>`,
		members: [
			{
				title: "Amol",
				subtitle: "Director of Experience",
				description: "He is always curious about different ways to teach kids and learn new things in the process. Loves to read books and tinker around with technology. Amol is highly involved in structuring the curriculum and developing this ecosystem. Amol also loves volleyball (maybe a little too much), sunny days spent outdoors, and the pursuit of the perfect bowl of ramen.",
				image: require("./../assets/amol.jpeg"),
			},
			{
				title: "Taaha",
				subtitle: "Student Success",
				description: "Taaha loves to interact and talk with different kids about video games, sports, and school. In his free time, Taaha hangs out with family, plays games with friends, and practices his coding skills. Teaching is something that he enjoys very much, just like eating foods from various cultures.",
				image: require("./../assets/taaha.png"),
			},
			{
				title: "Vishal",
				subtitle: "Student Success",
				description: "Vishal enjoys learning new crafts and honing new skills and is deeply interested in the world of computer science. He fully believes in a growth mindset and will gladly provide unique commentary and styles for each student. Apart from coding, Vishal is a Taekwondo black belt and enjoys playing video games, painting, and working out.",
				image: require("./../assets/vishal.jpeg"),
			},
			
			{
				title: "Pranjal",
				subtitle: "Student Success",
				description: "Pranjal is a coding enthusiast Who likes to give real life examples to make the concepts more interesting. She likes to find common ground with strangers and make people comfortable in her presence.She is an experienced teacher who loves to code and spend her free time writing ,watching shows and building logics.",
				image: require("./../assets/pranjal.jpg"),
			},
			{
				title: "Jidnyasa",
				subtitle: "Student Success",
				description: "I love coding and I love to teach and in codeablock I get an opportunity to enjoy both my hobbies. I also like to design games which will intriguing for the kids. I like to take active participation in curriculum designing. I am outdoorsy person, so in my free time I like to go out, hang out with friends and try out new food dishes.",
				image: require("./../assets/jidnyasa.jpg"),
			},
			{
				title: "Lily",
				subtitle: "Student Success",
				description: "Lily is a student at BYU-Idaho, studying software engineering. She loves programming and spends a lot of time working on coding projects for school. When teaching, she tries to get to know each student to understand their various interests, strengths, and areas of improvement. In her free time, you can find Lily watching movies with friends, playing piano, and teaching the Brazilian Jiu-Jitsu class on campus.",
				image: require("./../assets/lily.jpg"),
			},
			{
				title: "Sambhav",
				subtitle: "Student Success",
				description: " I love to code and play chess and basketball. I have been coding since 3rd grade and have been teaching coding and chess since middle school. I enjoy making games in Scratch and Python. I also enjoy hiking and biking. I am a full-time tortoise caretaker.",
				image: require("./../assets/sambhav.png"),
			},
			{
				title: "Dev",
				subtitle: "Student Success",
				description: "Dev is currently a student at Virginia Tech, where he is majoring in Business Information Technology. He loves to code and teach children how to program and build their computer skills. He puts an emphasis on making sure that his students understand what they are learning rather than simply regurgitating information. Additionally, Dev loves to drum and make music, as well as play sports with his friends.",
				image: require("./../assets/dev.jpg"),
			}
		],
		contactUs: {
			title: `<div style='text-align: center' >CONTACT US</div>`,
			info: [
				{label: "Email Address" , value: "contact@codeablock.com"}, {label: "Phone Number", value: "+1 571-969-4104" }
			],
			style: {
				backgroundImage: require("./../assets/contactUs3.jpg"),
				backgroundColor: "white"	
			}
		},
		whatwedo: {
			title: `<div style='width: 100%; text-align: center;'>Our Mission</div>`,
			description: `<p style="color:black; text-align: center">Our mission is to make coding fun and interactive for kids. Our goal is to promote lifelong learning with introduction to fundamental concepts of programming which become building blocks for future school topics. Code a block strives to excel in curriculum that delivers content keeping in mind the kids learning pattern and needs.</p>`,
			style:{
				webScreen:{
					static: true,
					backgroundColor: 'white',
					backgroundImage: require("./../assets/ourmission.jpg"),
					opacity:0.5, 
					title:{
						effect:'fade-left',
						align:'flex-start',
						color:'white'
					},
					description:{
						effect: 'fade-right'
					},
				},
				mobileScreen: {
					backgroundType: 'background-cover',
					backgroundImage: require("./../assets/whatwedo.jpeg"),
					title:{
						effect:'fade-left',
						color: 'white'
					},
					description:{
						effect: 'fade-right',
						color: 'white'
					}
				}
			}
		}
	},
	style = {
		backgroundColor: "#f9f9f9"
		// backgroundType: "background-cover",
		// backgroundImage: require("./../assets/aboutUs.jpg")
	}