import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import "./members.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: "300px",
    enterTouchDelay: 300,
    boxShadow: theme.shadows[1]
  },
}))(Tooltip);

export default function Members({members}) {

  return (
    <div className="members row-flex center-flex">
        {members.map((item, index)=>(
          <div key={index} className="member row-flex center-flex">
            <HtmlTooltip arrow
              title={
                <React.Fragment>
                  <div style={{padding: "20px"}}>
                    <div className="app-subtitle row-flex center-flex"><span>{item.title}</span></div>
                    <div className="app-subheader row-flex"><strong>Bio:</strong></div>
                    <div className="app-description">{item.description}</div>
                  </div>
                </React.Fragment>
              }
            >
              <div>
                <div className="row-flex center-flex">
                  <Avatar aria-label="member">
                        {item.image && <img src={item.image}/>}
                        { !item.image && (item.title.split(" ").map(title=>(title[0].toUpperCase())).join("") ) }
                  </Avatar>
                </div>
                <div className="app-subtitle row-flex center-flex"><span>{item.title}</span></div>
                <div className="app-description row-flex center-flex" style={{textAlign: "center"}}><span>{item.subtitle}</span></div>
              </div>
            </HtmlTooltip>
          </div>
        ))}
    </div>
  );
}