import React, {useContext, useEffect} from 'react';
import './fullscreen.css';
import { config } from './config';
import { Button, AppBar, Toolbar, IconButton, Fab } from '@material-ui/core';
import  MenuIcon from '@material-ui/icons/Menu';
import  CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Broadcast } from "./../broadcast";

const Fullscreen=()=>{
  const {broadcast} = useContext(Broadcast);
  const menus = config();
  const classNameforItem = (menu)=>{
    document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
    document.querySelectorAll(`.${menu.key}`).forEach(item=>item.classList.add('active-page'));
    document.querySelector(".menu-screen").classList.remove('menu-open');
      broadcast.set("activePage", menu.path);
    if(!menu.path)
      broadcast.set(menu.key, true);

  }

  useEffect(()=>{
    let key = (broadcast.get('activePage')||"").replace(/\//g,"");
      if(key.indexOf("#")>-1) key = key.split("#")[1];
      else key = "";
    document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
    if(key) 
      setTimeout(function() {
        document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));  
      }, 100);
  },[broadcast.get("activePage")])

  const getLink =(item)=>{
    if(item.path.indexOf("#")>-1)
      return (<HashLink smooth key={item.key} className={"menu-item " + item.key} to={item.path} onClick={evt=>classNameforItem(item)}>
                <span className="menu-item-title">{item.title}</span>
                <span className="menu-item-underline"></span>
             </HashLink>);
    return (<Link key={item.key} className={"menu-item " + item.key} to={item.path} onClick={evt=>classNameforItem(item)}>
                  <span className="menu-item-title">{item.title}</span>
                  <span className="menu-item-underline"></span>
            </Link>);
  }

  return (
        <div className="full-menu">
          <IconButton className="menu-icon white-button-icon" onClick={evt=>document.querySelector(".menu-screen").classList.add('menu-open')}>
            <MenuIcon />
          </IconButton>
          <div className="full-menu-container">
            <div className="menu-screen column-flex center-flex">
              {menus.map(item=>getLink(item))}
              <br></br>
              <Fab color="secondary" className="close-menu-icon" onClick={evt=>document.querySelector(".menu-screen").classList.remove('menu-open')}>
                <CloseIcon />
              </Fab>
            </div>
          </div>  
        </div>
  );
}

export default Fullscreen;
