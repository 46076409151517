import React from 'react';
import { data, style } from './config';
import './testimonials.css';

import Information from './../sdk/information/information';
import Feedbacks from './feedbacks';

import Feedbacks3 from './feedbacks3';
import WindowResize from './../sdk/windowResize';


function Testimonials() {
  const size = WindowResize();
  return (
    <Information id="testimonials" title={data.title} style={style}>
	    <div>
        <Feedbacks3 data={data.parents} id="parents"/>
        <Feedbacks3 data={data.students} id="students"/>
      </div>
    </Information>
  );
}

export default Testimonials;
