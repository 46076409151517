import React from 'react';
import { data, style } from './config';
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { Broadcast } from "./../broadcast";

import './hiring.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Hiring() {
 	const {broadcast} = React.useContext(Broadcast);
 	const openDialog = broadcast.get("hiring") || false;

	const handleToggle = (val) => {
	    broadcast.set("hiring", val);
	};


	React.useEffect(()=>{});

  return (
  	<Dialog
  		className="hiring-dialog"
        disableEscapeKeyDown={true}
        open={openDialog}
        onClose={evt=>handleToggle(false)}
        TransitionComponent={Transition}
      >
      	<div className="hiring row-flex" id="hiring">
	      	<div style={{width: '100%'}} className="row-flex center-flex">
	    		<div className="row-flex center-flex">
	    			<div className="free-space"></div>
	    			<div className="app-description" data-aos="flip-down" data-aos-delay="500" data-aos-easing="ease-in-out"><span>{data.title.toUpperCase()}</span></div>
	    			<div className="free-space"></div>
	    			<div>
			          <IconButton
			            edge="start"
			            color="inherit"
			            onClick={evt=>handleToggle(false)}
			            aria-label="close"
			          >
			            <CloseIcon />
			          </IconButton>
			        </div>
	    		</div>
	       		<iframe data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease-in-out" src={data.url} title="Hiring form">
				</iframe>
	      	</div>
	    </div>
     </Dialog>
  );
}

export default Hiring;
