import React, {useEffect} from 'react';
export default function Parallax({ image, opacity, className, id, gradient, backgroundColor }) {
  let hexValues = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e"];
  function setBackgroundColor(){
  	if(!gradient) return backgroundColor || 'rgb(0,0,0)';
		return "linear-gradient(" + Math.round( Math.random() * 360 ) + "deg, " + randomColor() + ", " + randomColor() + ")";
	}

	function randomColor(){
	 	let result = "#";
	    for ( let i = 0; i < 6; i++ ) 
	      result+=hexValues[Math.round( Math.random() * 14 )];
	    return result;
	}

  return(
     <style>
      	{`
      		#${id} {
			  position: relative;
			  background-attachment: fixed;
			  background-position: center;
			  background-repeat: no-repeat;
			  background-size: cover;
		  	  background-image: url("${image}");
		  	}
			 #${id}::before {   
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: ${setBackgroundColor()};
				opacity: ${opacity ||  0.7};
			}
		`}
      </style>
  )
}