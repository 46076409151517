import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

function Account({form, onSubmit}) {

  const [_form, _setForm] = React.useState({...form});
  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    _setForm({..._form});
  }
  
  const hasError = ()=>{
    return _form.hasError(['name','phoneNumber','email','studentName','studentAge']);
  }

  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
  
        <div style={{width:'100%'}} className="account column-flex form-container">
          <Typography style={{textAlign: "center"}} variant='h6'>CONTACT DETAILS</Typography>
          <br/>
          <br/>
          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.name.is_required}
              error = {_form.name.error?true:false}
              id="name"
              value={_form.name.value || _form.name.default||""}
              label={_form.name.label}
              helperText={_form.name.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('name', event.target.value)}
            />
          </div>
            

          <div>
            <div className={"MuiTextField-root " + (_form.phoneNumber.error?" error-MuiTextField-root":"")}>
              <PhoneInput
                  country={'us'}
                  inputProps={{
                    id: 'phoneNumber',
                    required: _form.phoneNumber.is_required
                  }}
                  onChange={(val)=>handleChange('phoneNumber', val)}
                  defaultErrorMessage= {_form.phoneNumber.error||""}
                  value={_form.phoneNumber.value || _form.phoneNumber.default||""}
                   specialLabel={_form.phoneNumber.label + (_form.phoneNumber.is_required?" *":"")}
              />
              <p className="phoneinput-error">{_form.phoneNumber.error||""}</p>
            </div>
          </div> 

          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.email.is_required}
              error = {_form.email.error?true:false}
              id="email"
              value={_form.email.value || _form.email.default||""}
              label={_form.email.label}
              helperText={_form.email.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('email', event.target.value)}
            />
          </div> 

          <Divider style={{marginBottom: '30px'}}/>
          
          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.studentName.is_required}
              error = {_form.studentName.error?true:false}
              id="studentName"
              value={_form.studentName.value || _form.studentName.default||""}
              label={_form.studentName.label}
              helperText={_form.studentName.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('studentName', event.target.value)}
            />
          </div> 

          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.studentAge.is_required}
              error = {_form.studentAge.error?true:false}
              id="studentAge"
              label={_form.studentAge.label}
              value={_form.studentAge.value || _form.studentAge.default||""}
              helperText={_form.studentAge.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('studentAge', event.target.value)}
              type="number"
              inputProps={{min:_form.studentAge.min, max: _form.studentAge.max }}
            />
          </div> 
        </div>

        <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
            <Button  disabled style={{width: "120px"}}>Back </Button> 
             <div style={{width: "20px"}}></div>
            <Button variant="contained" 
                disabled = {hasError()}
                 style={{color:'white', minWidth: '120px', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
                     onClick={()=>onSubmit(1)}>Next</Button>
        </div>

      </form>
  );
}

export default Account;
