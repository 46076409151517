const SDKForm = {
	hasError: function(keys){
		if(!keys || !keys.length) keys = Object.keys(this);
		for(const key of keys){
			if(!this[key] || typeof(this[key])=='function' || typeof (this[key])!='object') continue; 
			if(this[key].error) return true;
			if(this[key].is_required && (this[key].value==="" || !this[key].hasOwnProperty('value'))) return true;
		}
		return false;
	},
	
	onChange: function(id, value){
		this[id].error = '';
		if(this[id].is_required && value==="")
			this[id].error = 'Field is required';
		else if(this[id].hasOwnProperty('validation'))
		  this[id].validation(value);
		this[id].value = value;
	},

	clear: function(){
		for(const key in this){
			if(!this[key] || typeof(this[key])=='function' || typeof (this[key])!='object') continue; 
			delete this[key].value;
			if(this[key].default) this[key].value = this[key].default;
		}
	},

	values: function(){
		let res = {};
		for(const key in this){
			if(!this[key] || typeof(this[key])=='function' || typeof (this[key])!='object') continue; 
			res[key] = this[key].value;
		}

		return res;
	}
}
export default SDKForm;