import React, {useContext, useEffect} from 'react';
import Introduction from './../introduction/introduction';
import Courses from './../courses/courses';
import Company from './../company/company';
import Booking from './../booking/booking';
import Hiring from './../hiring/hiring';
import AboutUs from './../aboutUs/aboutUs';
import Testimonials from './../testimonials/testimonials';

import Projects  from './../projects/projects';


import { config } from './../routes/config';

import { Broadcast } from "./../broadcast";

import './home.css';

function Home() {
	console.log("Home");
 	const {broadcast} = useContext(Broadcast);
 	let active = broadcast.get("activePage");
	const menus = config();
	let anchors = [];
	let onScroll = null;
	const setActiveMenu = ()=>{
	 	let previous = null;
        for(var i=0;i <anchors.length; i++){
          	if(window.pageYOffset<=anchors[i].offsetTop){
	        	let tmp = previous?previous.path:active;
	        	if((window.pageYOffset + anchors[i].clientHeight * 0.5)>=anchors[i].offsetTop) tmp = anchors[i].path;
	          	active = tmp;
	          	if(active!=broadcast.get('activePage'))
	          		broadcast.set("activePage",active);
	          	return;
	        } 
          	previous = anchors[i];
        }
    }
  

  	
  	const scrolling = ()=>{
  		if(onScroll) clearInterval(onScroll);
		onScroll = setInterval(function() {
			setActiveMenu();
		 	clearInterval(onScroll);	
			onScroll = null;
		}, 100);

		// if(window.pageYOffset==0) return document.querySelector(".app-toolbar").classList.add('transparent-toolbar');
  //   	return document.querySelector(".app-toolbar").classList.remove('transparent-toolbar');
  	}

  	window.onscroll = ()=> {
		scrolling();
	  }
  

	useEffect(()=>{
		if(!anchors.length)
			anchors = menus.filter(item=>(item.path&& document.querySelector("#" + item.key))).map(item=>{
				let ele = document.querySelector("#" + item.key);
		        const clientHeight  = ele.clientHeight;
		        const offsetTop = ele.offsetTop;
				return {key: item.key, clientHeight: ele.clientHeight, offsetTop: ele.offsetTop, path: item.path}
				
			});
		scrolling();
	},[active]);
	
	  return (
	    <div className="home">
	      <Introduction />
	      <Company />
	      <Courses />
	      <Testimonials />
	      <Projects />
	      <AboutUs />
	      <Hiring />
	      <Booking />
	    </div>
	  );
	}

export default Home;
