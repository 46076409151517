import React, {useContext, useEffect, useState} from 'react';
import './inline.css';
import { config } from './config';
import { Button } from '@material-ui/core';
import { useLocation, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Broadcast } from "./../broadcast";

const Inline =(props)=>{
  const {broadcast} = useContext(Broadcast);
  const menus = config();
 
  const classNameforItem = (menu)=>{
    let key = (menu.key||"").replace(/\//g,"");
    document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
    document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));
    broadcast.set("activePage", menu.path);
    if(!menu.path)
      broadcast.set(menu.key, true);
  }

  useEffect(()=>{
      let key = (broadcast.get('activePage')||"").replace(/\//g,"");
      if(key.indexOf("#")>-1) key = key.split("#")[1];
      else key = "";
      document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
      if(key) 
        setTimeout(function() {
          document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));  
        }, 100);
  },[ broadcast.get("activePage")])

  const getLink =(item)=>{
    if(item.path.indexOf("#")>-1)
      return (<HashLink smooth key={item.key} className={"menu-item " + item.key} to={item.path} onClick={evt=>classNameforItem(item)}>
                <span className="menu-item-title">{item.title}</span>
                <span className="menu-item-underline"></span>
             </HashLink>);
    return (<Link key={item.key} className={"menu-item " + item.key} to={item.path} onClick={evt=>classNameforItem(item)}>
                  <span className="menu-item-title">{item.title}</span>
                  <span className="menu-item-underline"></span>
            </Link>);
  }

  return (
    <div className="inline-menu">
      <div className="row-flex">
        {menus.map(item=>getLink(item))}
      </div>  
    </div>
  );
}

export default Inline;
