import React, {useEffect} from 'react';
export default function AutoType({children, cursorColor, text, id, className, speed}) {
	let cursor = 0;
	let name = id?("#" + id):className?`.${className}`:"#auto-type";
	const typeWriter =()=> {
	  if (cursor < (text||"").length) {
	  	let ele = document.querySelector(name);
	  	if(ele) ele.textContent += text.charAt(cursor);
	    cursor++;
	    setTimeout(typeWriter, speed||50);
	  }
	}

	useEffect(()=>{
		setTimeout(typeWriter, 1000);
	},[])

  return(
  	<div className="auto-type" id="auto-type">
	     <style>
	      	{`
				 ${name}::after {   
					border-right-color: ${cursorColor||"orange"};
					content: "";
					padding-right: 20px;
					border-right: 3px solid ${cursorColor||"orange"};
					animation: typing 3.5s steps(40, end),
					             blink-caret .75s step-end infinite;
				}

				@keyframes typing {
				  from { width: 0 }
				  to { width: 100% }
				}

				/* The typewriter cursor effect */
				@keyframes blink-caret {
				  from, to { border-color: transparent }
				  50% { border-color: ${cursorColor||"orange"}; }
				}
			`}
	      </style>
	      {children}
    </div> 
  )
}