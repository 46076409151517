import React from 'react';
import Information from './../sdk/information/information';
import WindowResize from './../sdk/windowResize';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import './contactUs.css';


export default function ContactUs({config}) {
   const size = WindowResize();
    let currentStyle = config.style.webScreen?config.style.webScreen:config.style;
    if(size.width<=850) currentStyle = config.style.mobileScreen?config.style.mobileScreen:currentStyle;

  return (
  		<Information  id="contact-us" subtitle={config.title} style={{...config.style, backgroundImage:null}}>
    		<div className="contactus-container">
    			<div className="contactus-content row-flex">
    				<div className="contactus-content-side row-flex center-flex">
    					<div className="column-flex">
	    					{config.info.map((item, index)=>(
						    	<div key={index} className="info-content">
							    	<div className="app-subtitle" dangerouslySetInnerHTML={{__html: item.label}}></div>
							    	<div className="app-description" dangerouslySetInnerHTML={{__html: item.value}}></div>
						    	</div>
						    ))}
		              		<div className="row-flex center-flex">
						    	<a href="https://www.facebook.com/codeablock" className="social-media fb" target="_blank">
						    		<FacebookIcon/>
						    		<div className="fb-container"></div>
						    	</a>
						    	<a href="https://www.instagram.com/codeablock" className="social-media insta"  target="_blank">
						    		<InstagramIcon/>
						    	</a>
							</div>
						</div>
	              	</div>

    				<div className="contactus-image-side row-flex center-flex">
    					<img src={config.style.backgroundImage} />
    				</div>
    			</div>
    		</div>
    	</Information>
	  );
}

