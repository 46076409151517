import React from 'react';
import Information from './../sdk/information/information';
import Categories from './categories';
import Grades from './grades';
import { data, style } from './config';

import './courses.css';

function Courses() {
	console.log('courses');
  return (
    	<div id="courses">
    		
      		<Information id="courses-grades" title={data.grades.title} style={data.grades.style}>
      			<Grades grades={data.grades.items}/>
      		</Information>
    	</div>
  );
}

export default Courses;
