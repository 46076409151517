import React, {useState, useContext, useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Link} from "react-router-dom"; 
import { HashLink } from 'react-router-hash-link';

import Inline from './routes/inline';
import Fullscreen from './routes/fullscreen';
import AppRoutes from './routes/app-routes'; 

import ExtensionIcon from '@material-ui/icons/Extension';

import { AppBar, Toolbar, Button } from '@material-ui/core';

import { Broadcast } from './broadcast';

import Information  from './sdk/information/information';
import './App.css';

import {data, style } from './config';

function App() {
  const [channels, setChannels] = useState({
      'activePage': window.location.pathname + window.location.hash,
      'openBooking': false

    });
  const memo  = useMemo(()=>({channels, setChannels}), [channels, setChannels]);
  let broadcast = {
    set:(key, val)=>{
      memo.channels[key] = val;
      memo.setChannels({...memo.channels});
    },
    get:(key)=>{
      return memo.channels[key];
    },
    delete:(key)=>{
      delete memo.channels[key];
      memo.setChannels({...memo.channels});
    }
  }

  let handleBooking = (evt)=>{
    broadcast.set("openBooking", true);
  }

  // window.onscroll = function() {
  //   if(window.pageYOffset==0) return document.querySelector(".app-toolbar").classList.add('transparent-toolbar');
  //   return document.querySelector(".app-toolbar").classList.remove('transparent-toolbar');
  // }
  

  useEffect(()=>{
  },[]);

  return (
    <div className="App">
      <Router>
        <Broadcast.Provider value={{channels: memo.channels, broadcast}}>
        <AppBar position="fixed" className="app-toolbar">
          <Toolbar>
            <div>
              <Button className="menu-icon">
                <HashLink to="/home/#introduction" smooth onClick={evt=>broadcast.set('activePage', '/')} className="logo row-flex no-wrap center-flex">
                    <div className="logo-image"><img src={ require('./assets/logo.png') } /></div>
                </HashLink>
              </Button>
            </div>
            <div className="inline" active_page={channels["activePage"]}><Inline/></div>
            <div className="free-space"></div>
            <Button variant="outlined" className="booking-button app-button" onClick={handleBooking}>Book a Class</Button>
            <div className="fullscreen" active_page={channels["activePage"]}><Fullscreen/></div>
          </Toolbar>
        </AppBar>
        <div className="app-body">
          <AppRoutes/>
        </div>
        </Broadcast.Provider>
      </Router>
      <footer>
          <Information id="app-footer" style={style}>
              <div className="row-flex">
                 {(data.footers||[]).map((item, index)=>(
                    <div className="footer-container" key={index}>
                      <div className="app-subtitle">{item.label}</div>
                      <br/>
                      <div>{item.contents.map((content, idx)=>(
                          <div className="footer-content" key={idx}>
                            <a href={content.url} className="app-description" target={content.newTab?"_blank":""}>{content.label}</a>
                          </div>
                        ))}</div>
                    </div>
                  ))}
              </div>
          </Information>
      </footer>
    </div>
  );
}

export default App;
