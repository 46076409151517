import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import Registration from './registrationResource';
import RegistrationSuccess from './registrationSuccess';
import './studentRegistration.css';
import Account from './account';
import Student from './student';
import WindowResize from './../sdk/windowResize';

import { data, style } from './config';

function StudentRegistration() {
    const size = WindowResize();
    const registration = new Registration();
  const [activeStep, setActiveStep] = React.useState(0);
  const [ isSave, setSave ] = React.useState(0);
  const [ errorMessage, setErrorMessage ] = React.useState("");

 
  const getSteps = () =>{
      return [
        {
            label: 'Contact Information',
            template: <Account form={data.form} onSubmit={submitForm} />
        },{
            label: 'Course Information',
            template: <Student form={data.form} onSubmit={submitForm} />
        } 
    ];
  }

  const submitForm = async (val, callback)=>{
    if(typeof val ==='number') return setActiveStep((val));
    if(data.form.hasError()) return;
    let value = data.form.values();
    setSave(1);
    setErrorMessage("");
    let res = await registration.post(null, value);
    callback();
    if(res.hasOwnProperty("error_message") || res.hasOwnProperty("error")){
      setSave(0);   
      return setErrorMessage(res["error_message"] || res["error"]); 
    }
    setSave(2);
    data.form.clear();
  }

  const steps = getSteps();  

  return (
        <div style={{width:'90%'}}>
            {isSave==1?<LinearProgress />:<div/>}
            {isSave==2?(
                <RegistrationSuccess/>
            ):(
                <React.Fragment>
                    <div className="row-flex center-flex" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">SESSION SIGNUP</Typography ></div>
                    <div style={{margin: '20px'}} className="row-flex center-flex">
                        <div>Please fill in this sign up form to assist us in scheduling your class.</div>   
                    </div>

                    <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((item, index)=>(
                        <Step key={item.label}>
                            <StepButton completed={activeStep>index}></StepButton >
                        
                        </Step>
                    ))}
                    </Stepper>      
                    
                    <div  className="row-flex center-flex">
                        <div style={{width:  size.width>=850?'70%':'100%'}}>{steps[activeStep].template}</div>
                    </div>       
                </React.Fragment>          
            )}
            <br/>            {
                <Collapse in={errorMessage?true:false}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setErrorMessage("");
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }

                    >
                      {"Error: " + errorMessage}
                    </Alert>
                </Collapse>
            }
        </div>  
  );
}

export default StudentRegistration;
