import React from 'react';
import Information from './../sdk/information/information';
import Members from './members';

import ContactUs from './contactUs';
import './aboutUs.css';
import { data, style } from './config';


export default function AboutUs() {
  return (
  		<React.Fragment>

	    	<Information  id="about-us" title={data.title} style={{...data.whatwedo.style.webScreen, backgroundImage:null}}>
	    		<div className="company-container">
	    			<div className="company-content row-flex">
	    				<div className="row-flex center-flex">
			              	{data.whatwedo.subtitle && <div className="app-subtitle" dangerouslySetInnerHTML={{__html: data.whatwedo.subtitle}}></div>}
			              	{data.whatwedo.description && <div className="app-description" dangerouslySetInnerHTML={{__html: data.whatwedo.description}}></div>}
		              	</div>
	    			</div>
	    		</div>
	    	</Information>
	    	{
	    	/*<Information  id="team-member" subtitle={data.subtitle} description={data.description} style={{}}>
	    		<Members members={data.members}/>
	    	</Information>*/
	    	}
	    	<ContactUs config= {data.contactUs} />
    	</React.Fragment>
	  );
}

