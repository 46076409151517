export { data, style };
const data ={
		whoweare: {
			title: `<div style='text-align: center'>Who We Are</div>`,
			// subtitle:`<div style="text-align: center">Code a block is an online learning platform for kids from ages 8 through 15, where they learn computational thinking and logic development by creating games in scratch and python.</div>`,
			description: `
					<div >Code a block is an online learning platform for kids from ages 8 through 15, where they learn computational thinking and logic development by creating games in scratch and python.</div>
					<ul>
						<li>For this, we have created a multi-level curriculum where children can progress levels as they progress from easy to difficult concepts.</li>
						<li>These sessions are live and taught once a week by our expert instructors whose coding and communication skills are par excellence.</li>
						<li>This union enables us to provide interactive, fun-filled and comprehensive learning experience for your kids.</li>
					</ul`,
			style:{
				webScreen:{
					static: true,
					partition: 40,
					backgroundImage:require("./../assets/whoweare.jpg"),
					backgroundColor: '#f9f9f9',
					contentBackgroundColor: 'yellow',
					opacity:0.5, 
					title:{
						effect:'fade-right',
						align:'flex-start'
					},
					description:{
						effect: 'fade-left'
					}
				},
				mobileScreen: {
					backgroundType: 'background-cover',
					backgroundImage: require("./../assets/whoweare.jpg"),
					title:{
						effect:'fade-right',
						color: 'white'
					},
					description:{
						effect: 'fade-left',
						color: 'white'
					}
				}
			}
		}
	},
	style = {
	
	}