import React, {useEffect} from 'react';
import './introduction.css';
import WindowResize from './../sdk/windowResize';

import Parallax from './../sdk/parallax/parallax';
import { data, style } from './config';
import AutoType from './../sdk/auto-type/auto-type';
import { Button } from '@material-ui/core';
import { Broadcast } from "./../broadcast";
import Information from "./../sdk/information/information";
const Introduction = ()=> {	
  const {broadcast} = React.useContext(Broadcast);
  const size = WindowResize();
  const handleBooking = ()=>{
    broadcast.set("openBooking", true);
  }
  useEffect(()=>{
  	console.log("introduction {useEffect}");
  }, []);	
  return (
    <Information id="introduction" title={data.title} style={{...style, backgroundType: size.width>850?style.backgroundType:'cover'}} className="introduction row-flex">
        <AutoType id="intro-description" text={data.description.toUpperCase()}>
              <div id="intro-description" className="app-subtitle" style={{textAlign:"center"}}></div>
          </AutoType>
          
          <div data-aos="zoom-in-up" data-aos-delay="1000" data-aos-easing="ease-in-out" className="row-flex center-flex">
            <Button variant="outlined" className="booking-button app-button" onClick={handleBooking}>Book a Class</Button>
          </div> 
    </Information>
  );
}

export default Introduction;
