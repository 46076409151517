export { data, style };
const data ={
		footers: [
			{
				label: "Company",
				contents:[
					{
						label: "About",
						url: "/home#about-us",
					},
					{
						label: "Contact Us",
						url: "/home#about-us"
					}
				]
			},
			{
				label: "References",
				contents:[
					{
						label: "Vector Images Created by rawpixel",
						url:"https://www.freepik.com/vectors",
						newTab: true
					}
				]
			}
		]	
	},
	style = {
		backgroundColor: "#1d262d"
	}