import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import Levels from './levels';
import Levels2 from './levels2';
import './grades.css';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// const StyledTabs = withStyles({
//   indicator: {
//     display: 'flex',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//     '& > span': {
//       maxWidth: 40,
//       width: '100%',
//       backgroundColor: 'white',
//     },
//   },
// })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

// const StyledTab = withStyles((theme) => ({
//   root: {
//     color: 'rgb(255, 255, 255)',
//     fontWeight: 600,
//     fontSize: '15px',
//     textTransform: 'uppercase',
//     letterSpacing: '3px',
//     marginRight: theme.spacing(1),
//     '&:focus': {
//       opacity: 1,
//     }
//   },
// }))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dark_theme: {
  	backgroundColor: "#181818"
  }
}));

const Grades = ({grades})=>{

  React.useEffect(()=>{},[]);
	const classes = useStyles();
  	const theme = useTheme();
	const [selected, setSelected] = React.useState(0);

	const handleChange = (event, newValue) => {
	    setSelected(newValue);
	};

	  return (
	  	<div className="grades">
			 <div className="row-flex center-flex grade-select-container">
			   {grades.map((item, index)=>(
            <div className="grade-button-container" key={index}>
              <div className={"grade-icon " + (index==selected?"grade-icon-active":"")}>
                <img className="grade-icon-image" src={item.icon} />
              </div>
              
              <Button variant="outlined" className={"grade-button " + (index==selected?"grade-button-active":"")} onClick={evt=>handleChange(evt, index)}>{item.title}</Button>
            </div>
          ))}
			 </div>
			 <div>
			      	{grades.map((item, index)=>(
			      		<TabPanel value={selected} index={index} key={index}>
			      			<div className="column-flex center-flex" style={{padding: '20px 0'}}>
			      				<div className="app-subtitle" dangerouslySetInnerHTML={{__html: item.description}} style={{textTransform: "uppercase"}}></div>
                    <div className="app-description"><span>{item.subtitle}</span></div>
			      			</div>
			      			{<Levels2 levels= {item.levels}/>}
			      		</TabPanel>
			      	))}
		    </div>
	    </div>
	  );
}

export default Grades;