export { data, style };
const data ={
		title: `<div style="text-align:center; color:rgb(0, 128, 55)">Student Projects</div>`,
		projects:[
			{
				title: 'Scratch',
				items: [
					{
						subtitle:    `<div style="color:white; text-align: center">Platformer</div>`,
						description: `<div style="color:white; text-align: center">Username: SpeedCarrot</div>`,
						url: "https://scratch.mit.edu/projects/629196585/embed",
						thumbnail: require("./../assets/platformer.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Creeper Run</div>`,
						description: `<div style="color:white; text-align: center">Username: MandaL0rian_</div>`,
						url: "https://scratch.mit.edu/projects/655123784/embed",
						thumbnail: require("./../assets/creeper_run.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Hell Run Platformer</div>`,
						description: `<div style="color:white; text-align: center">Username: kaursam02</div>`,
						url: "https://scratch.mit.edu/projects/655512503/embed",
						thumbnail: require("./../assets/hell_run_platformer.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Platform</div>`,
						description: `<div style="color:white; text-align: center">Username: ultimatechamp1000</div>`,
						url: "https://scratch.mit.edu/projects/594194309/embed",
						thumbnail: require("./../assets/platform.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Platformer game</div>`,
						description: `<div style="color:white; text-align: center">Username: kaurm10</div>`,
						url: "https://scratch.mit.edu/projects/606074339/embed",
						thumbnail: require("./../assets/platformerGame.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Techno- II Platformer II</div>`,
						description: `<div style="color:white; text-align: center">Username: -_FoxNinja_-</div>`,
						url: "https://scratch.mit.edu/projects/558707399/embed",
						thumbnail: require("./../assets/techno.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Untitled-4</div>`,
						description: `<div style="color:white; text-align: center">Username: MandaL0rian_</div>`,
						url: "https://scratch.mit.edu/projects/552632061/embed",
						thumbnail: require("./../assets/untitle4.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Banana Eater</div>`,
						description: `<div style="color:white; text-align: center">Username: MandaL0rian_</div>`,
						url: "https://scratch.mit.edu/projects/552268855/embed",
						thumbnail: require("./../assets/bananaEater.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Deep Thinking (A Platformer)</div>`,
						description: `<div style="color:white; text-align: center">Username: TheMan471375</div>`,
						url: "https://scratch.mit.edu/projects/559855648/embed",
						thumbnail: require("./../assets/deepThinking.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Fruit ninja</div>`,
						description: `<div style="color:white; text-align: center">Username: saanvi90</div>`,
						url: "https://scratch.mit.edu/projects/544918543/embed",
						thumbnail: require("./../assets/fruitNinja.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Shape Hide and Seek</div>`,
						description: `<div style="color:white; text-align: center">Username: MandaL0rian_</div>`,
						url: "https://scratch.mit.edu/projects/555975046/embed",
						thumbnail: require("./../assets/shapeHideandSeek.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Flappy Bird</div>`,
						description: `<div style="color:white; text-align: center">Username: MandaL0rian_</div>`,
						url: "https://scratch.mit.edu/projects/557601723/embed",
						thumbnail: require("./../assets/flappyBird2.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Flappy Bird</div>`,
						description: `<div style="color:white; text-align: center">Username: SpeedCarrot</div>`,
						url: "https://scratch.mit.edu/projects/559838937/embed",
						thumbnail: require("./../assets/flappyBird.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Weird Cat 101</div>`,
						description: `<div style="color:white; text-align: center">Username: navir11</div>`,
						url: "https://scratch.mit.edu/projects/552949565/embed",
						thumbnail: require("./../assets/weirdCat101.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Nishaan</div>`,
						description: `<div style="color:white; text-align: center">Username: Nishsayshi</div>`,
						url: "https://scratch.mit.edu/projects/546298198/embed",
						thumbnail: require("./../assets/nishaan.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Chase The Heart</div>`,
						description: `<div style="color:white; text-align: center">Username: Codegrl_21</div>`,
						url: "https://scratch.mit.edu/projects/540882696/embed",
						thumbnail: require("./../assets/chase_the_heart.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Hide and seek</div>`,
						description: `<div style="color:white; text-align: center">Username: LuvPandas_137Cute</div>`,
						url: "https://scratch.mit.edu/projects/530266100/embed",
						thumbnail: require("./../assets/hide_seek.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">About Me</div>`,
						description: `<div style="color:white; text-align: center">Username: souparmaan</div>`,
						url: "https://scratch.mit.edu/projects/514065548/embed",
						thumbnail: require("./../assets/aboutMe.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Soccer game</div>`,
						description: `<div style="color:white; text-align: center">Username: LuvPandas_137Cute</div>`,
						url: "https://scratch.mit.edu/projects/503896011/embed",
						thumbnail: require("./../assets/course112.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Brick Vs Ball</div>`,
						description: `<div style="color:white; text-align: center">Username: navir11</div>`,
						url: "https://scratch.mit.edu/projects/494204321/embed",
						thumbnail: require("./../assets/brickNBall.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Flappy bird night city</div>`,
						description: `<div style="color:white; text-align: center">Username: coder_unicorn16</div>`,
						url: "https://scratch.mit.edu/projects/481454772/embed",
						thumbnail: require("./../assets/flapping_bird.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Hoops hoops hoops</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/486584521/embed",
						thumbnail: require("./../assets/hoops.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Shapes story</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/483008252/embed",
						thumbnail: require("./../assets/shapes_story.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Taco Maze</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/486353546/embed",
						thumbnail: require("./../assets/taco_maze.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Shooter game</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/478390702/embed",
						thumbnail: require("./../assets/shooter_game.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Guess the numbe</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/463165017/embed",
						thumbnail: require("./../assets/guess_a_number.png"),
						actionType: 'play'
					},
					{
						subtitle:    `<div style="color:white; text-align: center">Hide and seek - Halloween theme</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/439589620/embed",
						thumbnail: require("./../assets/course122.png"),
						actionType: 'play'
					},
					{
						subtitle: 	 `<div style="color:white; text-align: center">An interactive story</div>`,
						description: `<div style="color:white; text-align: center">Username: spiritual_chocolate</div>`,
						url: "https://scratch.mit.edu/projects/463711499/embed",
						thumbnail: require("./../assets/an_interactive_story.png"),
						actionType: 'play'
					},
					{
						subtitle: 	 `<div style="color:white; text-align: center">My life story on scratch</div>`,
						description: `<div style="color:white; text-align: center">Username: ChessAK</div>`,
						url: "https://scratch.mit.edu/projects/469535514/embed",
						thumbnail: require("./../assets/my_life_story_on_scratch.png"),
						actionType: 'play'
					},
					{
						subtitle: 	 `<div style="color:white; text-align: center">Da interactive space animation</div>`,
						description: `<div style="color:white; text-align: center">Username: TheMan471375</div>`,
						url: "https://scratch.mit.edu/projects/459411961/embed",
						thumbnail: require("./../assets/da_interactive_space_animation.png"),
						actionType: 'play'
					},
					{
						subtitle: 	 `<div style="color:white; text-align: center">My Story</div>`,
						description: `<div style="color:white; text-align: center">Username: wonderwomenz</div>`,
						url: "https://scratch.mit.edu/projects/469535511/embed",
						thumbnail: require("./../assets/my_story.png"),
						actionType: 'play'
					}
				]
			},
			{
				title: "Python",
				items: [
					{
						subtitle: `<div style="color:white; text-align: center">Car Racing Game</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						thumbnail: require("./../assets/car_racing.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Learning Charts</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						thumbnail: require("./../assets/chart.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Speed Typing Code</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						thumbnail: require("./../assets/typing_speed_test.gif")
					},
					{
						subtitle: `<div style="color:white; text-align: center">Slide Puzzle</div>`,
						description: `<div style="color:white; text-align: center">Username: Govind</div>`,
						thumbnail: require("./../assets/slide_puzzle.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Tkinter Introduction</div>`,
						description: `<div style="color:white; text-align: center">Username: Govind</div>`,
						thumbnail: require("./../assets/tkinter_introduction.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Snake Game</div>`,
						description: `<div style="color:white; text-align: center">Username: Mihika</div>`,
						thumbnail: require("./../assets/snake.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Pong Game</div>`,
						description: `<div style="color:white; text-align: center">Username: Mihika</div>`,
						thumbnail: require("./../assets/pong.gif"),
					},
					{
						subtitle: `<div style="color:white; text-align: center">Space Invaders</div>`,
						description: `<div style="color:white; text-align: center">Username: vishal</div>`,
						thumbnail: require("./../assets/space_invaders.gif"),
					}
				]
			},
			{
				title: "App Lab",
				items: [
					{
						subtitle: `<div style="color:white; text-align: center">Rock Paper Scissors</div>`,
						description: `<div style="color:white; text-align: center">Username: Armaan</div>`,
						url: "https://studio.code.org/projects/applab/TzwB6L6Z-xZ-Hghnji33mprnMUJDecIYP19fE6jxq4A",
						thumbnail: require("./../assets/rock_paper_scissors2.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Rock Paper Scissors</div>`,
						description: `<div style="color:white; text-align: center">Username: Dhanush</div>`,
						url: "https://studio.code.org/projects/applab/z0M5693x_ampnSFpwS3ly5jpS0j9qlHUCZsgvtwXnBE",
						thumbnail: require("./../assets/rock_paper_scissors.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Click Speed</div>`,
						description: `<div style="color:white; text-align: center">Username: Avinash</div>`,
						url: "https://studio.code.org/projects/applab/4u_WkaVinhf0S94yP6fCvFGOajDVSbdSmlUZ_MPqvKo",
						thumbnail: require("./../assets/click_speed2.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Click Speed</div>`,
						description: `<div style="color:white; text-align: center">Username: Marley</div>`,
						url: "https://studio.code.org/projects/applab/wOJXw6aoUQZn9LaE3ftQU87aDLvX3u745UbXNaPrE98",
						thumbnail: require("./../assets/click_speed.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Project 1</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/9qvlz1QYPkrOY3lmeJKz1-Chhraf1epQYmRvppMHRqk",
						thumbnail: require("./../assets/appLab1.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Project 2</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/mniLaQ8N_3lG0RPnfkFABsfiH7AtNHQgdyTgiZn1J7g",
						thumbnail: require("./../assets/appLab2.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Multiplication</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/j_6XiNO2VYEIC3w6mLHqVmHsJ8zxHFCJx1mag8ECWa4",
						thumbnail: require("./../assets/appLab_multiplication.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Temperature Converter</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/5m0e9bm4UB5NOPQWGilhC5rfkxTfrdytEnv7nkEz9qE",
						thumbnail: require("./../assets/appLab_temperature_converter.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Sum</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/BIZ2Osf70Z0P2p-359yf5DrAtmppJqmUwL9jdooFH6Q",
						thumbnail: require("./../assets/appLab_sum.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Rhyme Tester</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/AM0w356jW-KcomqfsCFMc4_t186CqmtCz652AU1bukg",
						thumbnail: require("./../assets/appLab_rhyme_tester.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Shopping List</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/hF6gbDTqri0uqfBNxmHvE8XsHlCRbGAPoDFe65lcHvk",
						thumbnail: require("./../assets/appLab_add_item.png"),
						actionType: 'redirect'
					},
					{
						subtitle: `<div style="color:white; text-align: center">Rock Paper Scissors</div>`,
						description: `<div style="color:white; text-align: center">Username: N/A</div>`,
						url: "https://studio.code.org/projects/applab/mCP8PIqu3_akk3E-8F6brLLwvrq_2TH9PBx-wVL1MZY",
						thumbnail: require("./../assets/appLab_rps.png"),
						actionType: 'redirect'
					}
				]
			}

		]
	},
	style = {
		backgroundColor: "#f9f9f9",
	}