import React from 'react';
import { data, style } from './config';
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined';
import Slide from "@material-ui/core/Slide";
import Information from './../sdk/information/information'
import ImageContainer6 from './../sdk/imageContainers/imageContainer6';
import Button from '@material-ui/core/Button';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation  } from 'swiper';
import 'swiper/swiper-bundle.css';

import './projects.css';


SwiperCore.use([Pagination, Autoplay, Navigation ]);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

function Projects() {
 	const [openDialog, onOpenDialog] = React.useState(false);
 	const [url, setUrl] = React.useState("");

 	const [selected, setSelected] = React.useState(0);

 	const handleChange = (event, newValue) => {
	    setSelected(newValue);
	};

	const renderAction = (type, value)=>{
		if(type=='play') return (<IconButton aria-label="Play Me"  className="play-button" onClick={e=>{onOpenDialog(true); setUrl(value)}}>
									          <PlayCircleOutlineIcon/>
							 	</IconButton>);
		if(type=='redirect') return (<IconButton aria-label="Play Me"  className="play-button" onClick={e=>{window.open(value, '_blank')}}>
									          <OpenInNewOutlined/>
							 		</IconButton>);
		return <React.Fragment/>;
	}

  return (
  	<Information id="projects-container" title={data.title} style={style}>
  		<div className="grades">
	  		<div className="row-flex center-flex grade-select-container">
				   {data.projects.map((item, index)=>(
	            <Button variant="outlined" key={index} className={"grade-button " + (index==selected?"grade-button-active":"")} onClick={evt=>handleChange(evt, index)}>{item.title}</Button>
	          ))}
			</div>
  		</div>
  		<div>
	  		{data.projects.map((project, index)=>(
	 			<TabPanel value={selected} index={index} key={index} id="projects">
	 				<div className="projects">
	 					<Swiper
	 						autoplay={{
						   		delay: 1500,
						 	}}
					        breakpoints={{
							  480: {
			                      slidesPerView: 1,
			                    },
			                    620: {
			                      slidesPerView: 2,
			                    },
			                    860:{
			                      slidesPerView: 3,
			         			  slidesPerColumn: 2
			                    },
			                    1200: {
			                      slidesPerView: 4,
			                      slidesPerColumn: 2
			                    },
                   
					        }}
					        id="student-project"
 							pagination={{clickable: true}}
					        spaceBetween={5}
					        slidesPerView={1}
					         slidesPerColumnFill="row"
					      >
					        {project.items.map((item, indx)=>(
			 					 <SwiperSlide  className={(item.status?"hover":"") + " project"} key={indx} style={{"backgroundImage": "url("+ item.thumbnail + ")"}}>
						          	<div className="project-container" style={{backgroundColor: item.backgroundColor||"rgba(0, 0, 0, 0.75)"}}>
							          	<div className="column-flex center-flex" style={{height: "100%"}}>
							          		<div className="app-subtitle" dangerouslySetInnerHTML={{__html: item.subtitle}}></div>
		                					<div className="app-description" dangerouslySetInnerHTML={{__html: item.description}}></div>
							          		{renderAction(item.actionType, item.url)}
							          	</div>
						          	</div>
						         </SwiperSlide >
			 				))}
					    </Swiper>
		 				
	 				</div>

				</TabPanel>
			))}
		</div>
	  	<Dialog
	  		className="project-dialog"
	        open={openDialog||false}
	        TransitionComponent={Transition}
	        onClose={evt=>onOpenDialog(false)} 
	      >
	      	<div className="row-flex center-flex no-wrap">
	      		<div className="free-space"></div>
	    			<div>
			          <IconButton
			            edge="start"
			            color="inherit"
			            onClick={evt=>onOpenDialog(false)}
			            aria-label="close"
			          >
			            <CloseIcon />
			          </IconButton>
			        </div>
	      	</div>
	      	<div className="project-dialog" id="project-dialog">
		    	<iframe src={url} allowtransparency="true" width="100%" height="100%" frameBorder="0" scrolling="no" allowFullScreen></iframe>
		    </div>
	     </Dialog>
    </Information> 
  );
}

export default Projects;
