/******************************************
  For redirection, config can be supplied with either path or component. 
  If using path, expect the whole page will be reloaded
* title
* key: unique identity
* path: path to components
* subMenu: [ {title, path, component....}]
*******************************************/

export const config = ()=>{
	return [
		{
			title: "",
			key: "introduction",
			path: "/home#introduction",

		},
		{
			title: "Company",
			key: "company",
			path: "/home#company",
		},
		{
			title: "Courses",
			key: "courses",
			path: "/home#courses",

		},
		{
			title: "Testimonials",
			key: "testimonials",
			path: "/home#testimonials",
		},
		{
			title: "About Us",
			key: "about-us",
			path: "/home#about-us",
		},
		{
			title: "Hiring",
			key: "hiring",
			path: "",
		}
	];
};