import React from 'react';
import Information from './../sdk/information/information';

import './company.css';
import { data, style } from './config';

function Company() {
	let {whoweare} = data;
  return (
    	<Information  id="company" title={whoweare.title} subtitle={whoweare.subtitle} style={{...whoweare.style.webScreen, backgroundImage:null}}>
    		<div className="company-container">
    			<div className="company-content row-flex">
    				<div className="company-image-side row-flex center-flex">
    					<img src={whoweare.style.webScreen.backgroundImage}/>
    				</div>
    				<div className="company-content-side row-flex center-flex">
		              	{whoweare.description && <div className="app-description" dangerouslySetInnerHTML={{__html: whoweare.description}}></div>}
	              	</div>
    			</div>
    		</div>
    	</Information>
    	

  );
}

export default Company;
