import React from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
function RegistrationSuccess() {
  return (
      <div>
        <div className="image-container row-flex center-flex">
          <img src={require("./../assets/school_kids.jpg")} />
        </div>
        <div className="image-container row-flex center-flex">
          <div data-aos="flip-up" data-aos-delay="500">
            <Icon style={{color:'#12a118', fill:'#12a118', fontSize:'150px'}}>
              check_circle_outline
            </Icon>
          </div>
        </div>
        <div className="image-container column-flex center-flex">
           <Typography variant="h4" style={{textAlign:'center'}}>THANK YOU FOR REGISTERING!</Typography>
           <Typography variant="h6" style={{textAlign:'center'}}>A member from our team will be back to you shortly with the session timing and meeting details</Typography>
        </div>
      </div>
  );
}

export default RegistrationSuccess;
