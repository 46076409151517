import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Button from '@material-ui/core/Button';
import { Broadcast } from "./../broadcast";
import Information from './../sdk/information/information'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

import "./levels2.css";


SwiperCore.use([Pagination, Navigation]);


export default function Level2({levels}) {
  const {broadcast} = React.useContext(Broadcast);

  return (
    <Information id="levels"  style={{}}>
       <Swiper
                  breakpoints={{
                    480: {
                      slidesPerView: 1,
                    },
                    720: {
                      slidesPerView: 2,
                    },
                    1200:{
                      slidesPerView: 3,
                    },
                    1820: {
                      slidesPerView: 4,
                    },
                   
                  }}
                  id="level-swiper"
                  pagination={{clickable: true}}
                  spaceBetween={5}
                  slidesPerView={1}
                  navigation
                >

                {levels.map((item, index)=>(
                  <SwiperSlide  key={index}>
                      <Card className="level">
                        <div className="level-title-container row-flex center-flex" style={{backgroundColor: item.backgroundColor}}>
                            <div dangerouslySetInnerHTML={{__html: item.title}} style={{"width": "100%"}}></div>
                            <div style={{padding: "20px 0"}}>{item.prices.map((price, idx)=>(
                              <div className = "price row-flex align-center" key={idx}>
                                <div dangerouslySetInnerHTML={{__html: price}} style={{"width": "100%"}}></div>
                              </div>
                            ))}</div>
                          <div className="booking row-flex center-flex">
                            <Button variant="outlined" onClick={evt=>broadcast.set("openBooking", true)}>Book Now</Button>
                          </div>
                        </div>

                        <CardContent className="level-content">
                          <div className="app-description" dangerouslySetInnerHTML={{__html: item.description}}></div>
                            <List component="nav" aria-label="Materials">
                              {item.courses.map((course, idx)=>(
                                <ListItem key={idx}>
                                  <ListItemIcon>
                                    <DoneAllIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={course} />
                                </ListItem>
                                ))} 
                            </List>  
                        </CardContent>
                      </Card>
                  </SwiperSlide>
                ))}
          </Swiper>
      </Information>
  );
}

          