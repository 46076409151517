import React from 'react';
import Information from './../sdk/information/information';
import WindowResize from './../sdk/windowResize';


import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import "./feedbacks3.css";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
 
export default function Feedbacks2({data, id}) {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  
    const size = WindowResize();

  React.useEffect(()=>{

  },[]);

  return (
    <div className = "feedback-container3">
      <Information id={id} subtitle={data.title} style={{...data.style, backgroundColor: data.style.contentBackgroundColor}}>
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false}
            interval={15000}
            cssModule={AwesomeSliderStyles}
          >
            {data.feedbacks.map((item, idx)=>(
                (size.width>850)?(
                <div key={idx} className="feedback3-slide-container row-flex no-wrap">
                  <div className="image-container row-flex center-flex"><img src={item.image} style={{height: '100%'}}/></div>
                    {size.width>850 && <FormatQuoteIcon className="openQuoteIcon quoteIcon" style={{fontSize: "100px"}} />}
                  <div className="feedback3-content row-flex center-flex">
                    <div>
                        <div className="app-subtitle" dangerouslySetInnerHTML={{__html: item.title}} style={{"width": "100%"}}></div>
                        <div className="app-subheader" dangerouslySetInnerHTML={{__html: item.subtitle}} style={{"width": "100%", marginBottom: "20px", fontSize:"12px"}}></div>
                        <div className="app-description" dangerouslySetInnerHTML={{__html: item.description}} style={{"width": "100%"}}></div> 
                    </div>
                  </div>
                </div>):


                (<div key={idx} className="feedback3-slide-container">
                  <div className="column-flex center-flex">
                    <div style={{marginRight: "10px"}}>
                      <img src={item.image} style={{width: "120px", height: "120px", borderRadius:"50%", objectFit: "cover"  }}/>
                    </div>
                    <div>
                      <div className="app-subtitle" dangerouslySetInnerHTML={{__html: item.title}} style={{"width": "100%"}}></div>
                      <div className="app-subheader" dangerouslySetInnerHTML={{__html: item.subtitle}} style={{"width": "100%", fontSize:"12px"}}></div>
                    </div>
                  </div>
                  <div className="feedback3-content row-flex center-flex">
                    <div>
                        <div className="app-description" dangerouslySetInnerHTML={{__html: item.description}} style={{"width": "100%"}}></div> 
                    </div>
                  </div>
                </div>)

            ))} 
          </AutoplaySlider>
      </Information>

    </div>
  );
}