import React from 'react';
import { data, style } from './config';
import Dialog from "@material-ui/core/Dialog";
import Drawer from '@material-ui/core/Drawer';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import WindowResize from './../sdk/windowResize';

import StudentRegistration from './../studentRegistration/studentRegistration';

import { Broadcast } from "./../broadcast";
import Typography from '@material-ui/core/Typography';

import './booking.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Booking() {
	const size = WindowResize();
 	const {broadcast} = React.useContext(Broadcast);
 	const openDialog = broadcast.get("openBooking");

	const handleToggle = (val) => {
	    broadcast.set("openBooking", val);

	};

	const getRegistrationContent = ()=>{
		return (
			<div className="booking row-flex" id="booking">
		      	<div style={{width: size.width>=850?'650px':'100%'}} className="row-flex center-flex">
		    		<div className="row-flex center-flex">
		    			<div className="free-space"></div>
		    			<div>
				          <IconButton
				            edge="start"
				            color="inherit"
				            onClick={evt=>handleToggle(false)}
				            aria-label="close"
				          >
				            <CloseIcon />
				          </IconButton>
				        </div>
		    		</div>
		       		<StudentRegistration></StudentRegistration>
		      	</div>
		    </div>
		)
	}

  return (
  	<React.Fragment>
  		{(size.width<=850)?(
	  		<Dialog
		  		className="booking-dialog"
		        disableEscapeKeyDown={true}
		        open={openDialog}
		        onClose={evt=>handleToggle(false)}
		        TransitionComponent={Transition}
		      >	
		      	{getRegistrationContent()}
		    </Dialog>):(
		    <Drawer 
		     	anchor='right' 
		     	open={openDialog} 
		     	onClose={evt=>handleToggle(false)}
		      >
		      	{getRegistrationContent()}
	        </Drawer>)
  	   }
  	</React.Fragment> 
    );
}

export default Booking;
