import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';

function Student({form, onSubmit}) {
  const [_form, _setForm] = React.useState({...form});
  const [session, setSession] = React.useState(_form.sessionType.value);
  const [dateTimes, setDateTimes] = React.useState(daysAndTimes(_form.sessionType.value));
  const [loading, setLoading] = React.useState(false);

  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    _setForm({..._form});
  }
 
  const handleSessionChange = (value)=>{
  	_form.onChange('sessionDateTime', '');
    setDateTimes(daysAndTimes(value));
  	handleChange('sessionType', value);
  }	

  function daysAndTimes(sessionType){
  	if(sessionType=='summer') 
  		return [
  			{'label': 'Tuesday and Thursday morning','value': 'tue-thus morning'},
  			{'label': 'Tuesday and Thursday afternoon','value': 'tue-thus afternoon'},
  			{'label': 'Wednesday and Friday morning','value': 'wed-fri morning'},
  			{'label': 'Wednesday and Friday afternoon','value': 'wed-fri afternoon'}
  		];
  	

  	if(sessionType=='regular') 
  		return [
  			{'label': 'Weekdays after school','value': 'after school'},
  			{'label': 'Weekends morning','value': 'weekends morning'},
  			{'label': 'Weekends afternoon','value': 'weekends afernoon'}
  		];

  	return [];

  }

  const onAction = (item)=>{
    if(loading) return;
    setLoading(true);
    onSubmit(item, ()=>{
      setLoading(false);
    });
  }

  const hasError = ()=>{
    return _form.hasError(['course','sessionType','day','time']);
  }


  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
  
        <div style={{width:'100%'}} className="student column-flex form-container">
            <Typography style={{textAlign: "center"}} variant='h6'>COURSE DETAILS</Typography>
            <div className="row-flex center-flex">Select the courses you are interest in:</div>   
	        <div className="row-flex" style={{justifyContent:'space-evenly', alignItems: 'center', margin: '25px 0'}}>
	            <div> 
		             <Button
		                variant="contained"
		                onClick={evt=>handleChange('course', 'Scratch')}
		                style={{color:'white', minWidth: '120px', backgroundColor: _form.course.value=='Scratch'?'#629bf1':'#d6d2d2'}}
		             >
		             	<Typography variant="h6">SCRATCH</Typography>
		             </Button>
		            <Typography variant="body2" style={{width:'100%', textAlign:'center'}}>(Ages 8 & Up)</Typography>
	            </div>

              <div>
                 <Button
                    variant="contained"
                    onClick={evt=>handleChange('course', 'App Lab')}
                    style={{color:'white', minWidth: '120px', backgroundColor: _form.course.value=='App Lab'?'#629bf1':'#d6d2d2'}}
                 >
                  <Typography variant="h6">APP LAB</Typography>
                 </Button>
                 <Typography variant="body2" style={{width:'100%', textAlign:'center'}}>(Ages 10 to 12)</Typography>
              </div>
              
	            <div>
		             <Button
		                variant="contained"
		                onClick={evt=>handleChange('course', 'Python')}
		                style={{color:'white', minWidth: '120px', backgroundColor: _form.course.value=='Python'?'#629bf1':'#d6d2d2'}}
		             >
		             	<Typography variant="h6">PYTHON</Typography>
		             </Button>
		             <Typography variant="body2" style={{width:'100%', textAlign:'center'}}>(Ages 12 & Up)</Typography>
	            </div>
	        </div>
            
            <div className="course-type">
            	<div className="course-type-description" style={{margin: '25px 0'}}>
		          	<Typography style={{textAlign: "center"}} variant='h6'>COURSE TYPE & TIMING:</Typography>
			        <div className="row-flex center-flex">Regular session type is one day/week.</div>   
			        <div className="row-flex center-flex">Summer session type is two days/week.</div>   
		        </div>

		        <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.sessionType.error?true:false} required = {_form.sessionType.is_required}>
			        <InputLabel id="sessionType">{_form.sessionType.label}</InputLabel>
			        <Select
			          native
			          value={_form.sessionType.value || _form.sessionType.default||"regular"}
			          label={_form.sessionType.label}
             		  onChange={(event)=>{handleSessionChange(event.target.value)}}
			        >
          			  <option aria-label="None" value="" />
			          <option value={'summer'}>Yes</option>
			          <option value={'regular'}>No</option>
			        </Select>
			        <FormHelperText>{_form.sessionType.error||""}</FormHelperText>
			    </FormControl>

	          	<FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.sessionDateTime.error?true:false} required = {_form.sessionDateTime.is_required}>
			        <InputLabel id="sessionDateTime">{_form.sessionDateTime.label}</InputLabel>
			        <Select
			          native
			          label={_form.sessionDateTime.label}
			          value={_form.sessionDateTime.value ||_form.sessionDateTime.default||""}
             		  onChange={(event)=>{handleChange('sessionDateTime', event.target.value);}}
			        >
          			  <option aria-label="None" value="" />
			          {dateTimes.map(item=>(<option key={item.value} value={item.value}>{item.label}</option>))}
			        </Select>
			        <FormHelperText>{_form.sessionDateTime.error||""}</FormHelperText>
			    </FormControl>  	
	        </div>
        </div>
        <div style={{width:'100%', marginTop: '20px'}} className="row-flex center-flex">
            <Button  onClick={()=>onSubmit(0)} style={{width: "120px"}}>Back </Button>
            <div style={{width: "20px"}}></div>
             <Button variant="contained" 
                  disabled = {_form.hasError() || loading}
             		 style={{color:'white', minWidth: '120px', backgroundColor: (_form.hasError() || loading) ?'#e5e5e5':'#629bf1'}}
                     onClick={()=>onAction(_form)}>Register</Button>
        </div>
      </form>
  );
}

export default Student;
