export { data, style };
const data ={
		categories:{
			title: `<div style="color:rgb(244, 89, 47); text-align:center">Courses</div>`,
			items: [
				{
					icon: require("./../assets/scratch.png"),
					title: '<div style="text-align:center">Scratch</div>',
					description: '<div style="color:white">Create stories, games, and animations to share with other around the world</div>', 
					backgroundColor: "#0a77fe",
					static: true
				},
				{
					icon: require("./../assets/python.png"),
					title: '<div style="text-align:center">Python</div>',
					description: '<div style="color:white">Python is an interpreted, high-level and general-purpose programming language. Python\'s design philosophy emphasizes code readability with its notable use of significant whitespaces</div>',
					backgroundColor: "#2b5b84",
					static: true
				}
			],
			style: {
			}
		},
		grades: {
			title: `<div style="color:#cb6ce6; text-align:center">Courses</div>`,
			items: [
				{
					title: "Scratch",
					icon: require("./../assets/scratch.png"),
					subtitle:'Ages 8-12',
					description: '<div style="text-align:center">Introduction to Scratch and Game development</div>',
					levels:[
						{
							backgroundColor: 'rgba(77,151,255,0.1)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 1</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids programming concepts like loops, conditionals and event driven programming to create games in scratch.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",
							],
							courses: [
								'Logic Development',
								'Event Driven Programming',
								'Introduction to Game Design',
								'Loops and Iterations',
								'Selection and Conditionals'
							]
						},
						{
							backgroundColor: 'rgba(77,151,255,0.2)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 2</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to concepts of animation via storytelling, functions and some basic geometric concepts in game design',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Functions',
								'Animate a Story',
								'Procedural Programming',
								'Finite Loops and Desired Iterations',
								'Coordinate Systems and Mapping'
							]
						},
						{
							backgroundColor: 'rgba(77,151,255,0.3)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 3</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to concepts of user interactions in stories, cloning and some advanced extensions in scratch',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'User Interactive Stories',
								'Nesting (Nested if-else)',
								'STEM: Introduction to Gravity',
								'Clone Creation and Duplication',
								'Sound, Text-to-speech Extensions'
							]
						},
						{
							backgroundColor: 'rgba(77,151,255,0.4)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 4</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to concepts of Nested conditions, boundary detection and advance cloning',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Nested Loops',
								'Advanced Cloning',
								'Boundary Detection',
								'Maze Creations',
								'STEM: Geometry Angles'
							]
						},
						{
							backgroundColor: 'rgba(77,151,255,0.5)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 5</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to using Lists and handling the non linear movements in games using acceleration as the STEM topic',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Functions with Inputs',
								'Non-linear Movements',
								'Lists',
								'Tic Tac Toe with Lists',
								'STEM: Acceleration'
							]
						},
						{
							backgroundColor: 'rgba(77,151,255,0.6)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 6</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level the kids apply all he concepts taught in previous sessions to create their own platformer game.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Creating Platformer game',
								'Adding obstacles and rewards',
								'Design gameplay',
								'Designing Fall and Slopes',
								'STEM:Revisit Gravity'
							]
						}
					]
				},
				{
					title: "App Lab",
					icon: require("./../assets/applab.png"),
					subtitle:'Ages 10-12',
					description: '<div style="text-align:center">Introduction to App Lab</div>',
					levels:[
						{
							backgroundColor: 'rgba(0, 173, 188,0.1)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 1</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							description: 'This level introduces kids to App Lab and the design aspects of mobile apps, with showcasing some introductory concepts in computer science.',
							courses: [
								'Intro to Applab',
								'Input and Output statements',
								'Design concepts',
								'Events and Modules',
								'Design Calculator'
							]
						},
						{
							backgroundColor: 'rgba(0, 173, 188,0.2)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 2</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							description: 'This level takes a deeper dive into conditionals and looping and helps creating a mobile friendly game using those concepts',
							courses: [
								'Conditionals',
								'While Loop',
								'For Loop',
								'Random function & Guessing Game',
								'Game (Rock Paper Scissors)',
							]
						},
						{
							backgroundColor: 'rgba(0, 173, 188,0.3)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 3</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to the concept of functions in app lab and how to reuse code. It also extends the concepts of data structures by introducing LISTS',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Functions',
								'Basic math functions',
								'Functions with parameters',
								'Lists',
								'Memory game using Lists'
							]
						},
						{
							backgroundColor: 'rgba(0, 173, 188,0.4)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 4</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to text based coding, the kids will also learn about Strings manipulations',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Block to Text Syntax',
								'Loops and Functions',
								'Lists and Strings',
								'String searches and loops',
								'Creating the Hangman game'
							]
						},
						{
							backgroundColor: 'rgba(0, 173, 188,0.5)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 5</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to the advanced concepts of dictionaries and databases.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Dictionaries',
								'Dictionary operations',
								'Database introduction',
								'Database operations',
								'Student database system project'
							]
						},
						{
							backgroundColor: 'rgba(0, 173, 188,0.6)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 6</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to data visualization and graphs in Javascript',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Charts',
								'Graphs',
								'Data Visualization and Data',
								'Final Project'
							]
						}
												
					]
				},
				{
					title: "Python",
					icon: require("./../assets/python.png"),
					subtitle:'Ages 12-15',
					description: '<div style="text-align:center">Introduction to Python and Game development</div>',
					levels:[
						{
							backgroundColor: 'rgba(255,165,0,0.1)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 1</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							description: 'This level introduces kids to basic python syntax and looping, along with some math concepts in game design',
							courses: [
								'Input and Output Statements',
								'Iterations and Loops',
								'Conditional Statements',
								'Functions',
								'Game Development (Pong Game)'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.2)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 2</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							description: 'This level introduces kids to python concepts for storing and using data, string manipulations and create a game using those concepts.',
							courses: [
								'For Loop',
								'Strings',
								'Lists',
								'Tuples',
								'Game Development (Snake Game)',
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.3)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 3</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to python concepts of data structures and other storage mechanism in python along with some libraries and modules.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Data Structures',
								'Dictionaries',
								'Sets',
								'Classes and Objects',
								'Game Development (Space Invader)'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.4)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 4</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to python concepts of File Handling and extends on the concepts of Dictionaries and Built-in Functions.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'File Handling',
								'Story Creation (Files)',
								'Dictionaries Game',
								'Built-in Functions',
								'Game Development (Car Racing)'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.5)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 5</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces kids to advance topics of Polymorphism and Inheritance and UI module Tkinter.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Python Module - Tkinter',
								'Advanced functions',
								'Inheritance',
								'Polymorphism',
								'Game Development (Slide Puzzle)'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.6)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 6</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level we introduce kids to the concept of Data Visualization and Data transformation by using Jupyter notebooks',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Data Visualization - intro',
								'Data Visualization - Matplotlib',
								'Operator Overloading',
								'Project - covid 19 dataset',
								'Project - yahoo finance'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.7)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 7</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level introduces the kids to advance topics that can help them with Python and web development.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Error and Exception handling',
								'Regular expression in form validation',
								'Regular expression in file handling',
								'Urlib handling',
								'OpenCV Module'
							]
						},
						{
							backgroundColor: 'rgba(255,165,0,0.8)',
							title: '<div class="row-flex center-flex" style="letter-spacing:1px"><strong>LEVEL 8</strong></div>',
							subtitle: '8 live sessions, 45 mins each',
							description: 'This level is more for advanced students, where they learn the database functions in python and discuss about certification.',
							prices: [
								"<div class='column-flex center-flex'><strong>$130</strong><span style='margin-top:10px'>semi private (2 kids)</span></div>",							],
							courses: [
								'Type Racing Game Development',
								'Enhancing the User Interface of the game',
								'Quiz Game using Tkinter and Sqlite3',
								'Development of User Interface and levels as per difficulty',
								'Python Certification guidance'
							]
						}								
					]
				}
			],
			style: {
				backgroundColor: "rgba(250, 250, 250, 0.75)"
			}
		}
	},
	style = {
	}