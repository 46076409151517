export default class APIService{
	constructor(serverUrl, storageType){
		this.apiUrl = serverUrl;
		if(!storageType) this.storage = localStorage;
		else this.storage = sessionStorage;
	}

	set serverUrl(url){ this.apiUrl = url};

	set tokenKey(key) { this.tk = key };

	set resource(path) {this.path = path}

	getPath(params){
		if(!params || typeof(params)!='object') return this.path; 
		return Object.entries(params).reduce((res, item)=>{
			return res.replace(`:${item[0]}`, item[1]);
		}, this.path);
	}

	async post(params, body){
		let res = await this.request({method: 'POST', path: this.getPath(params), body: body});
		return res;
	}

	async get(params, body){
		let res = await this.request({method: 'GET', path: this.getPath(params), body: body});
		return res;
	}

	async put(params, body){
		let res = await this.request({method: 'PUT', path: this.getPath(params), body: body});
		return res;
	}

	async delete(params, body){
		let res = await this.request({method: 'DELETE', path: this.getPath(params), body: body});
		return res;
	}

	async request(params){
		let {method, path, body, headers} = params;
		if(!headers) headers = {'Content-Type': 'application/json'};
		const token = sessionStorage.getItem(this.tk);
		if(token) headers['Authorization'] = `Bearer ${token}`;
		let options = {method: method.toUpperCase(), headers}

		if(body && Object.entries(body).length) options.body = JSON.stringify(body);

		if(path[0] =='/') path = path.substring(1);
		const url = this.apiUrl + `/${path}`;
		try{
			let response = await fetch(url, options);
			response = await response.json();
			return this.handleResponse(response); 
		}catch(err){
			return {error: err};
		};
	}

	handleResponse(data){
		return data;
	}
}