export { data, style };
	const data ={
		title: `<div style="text-align:center" data-aos="flip-down" data-aos-delay="500" data-aos-easing="ease-in-out">CODE A BLOCK</div>`,
		description: `Coding and Game development design specifically for kids`
	},
	 style = {
		opacity: 0.75,
		backgroundImage: require("./../assets/aboutUs2.jpg"),
		backgroundType:'parallax',
		backgroundColor: 'linear-gradient(323deg, #8220c7, #32a924)',
		effect: 'flip-down'
	}